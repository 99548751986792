<template>
  <div>
    <v-card class="transparent" flat>
      <v-card-title>
        <span class="font-weight-bold">
          {{ $route.name }}
        </span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openAddDialog()">
          <v-icon>mdi-plus</v-icon>
          <h3>{{ $t("add") }} {{ meta.single }}</h3>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="my-3">
          <v-col cols="12" md="3">
            <v-text-field
              dense
              v-debounce:500ms="getItems"
              class="mr-1"
              v-model="filter.FullName"
              :label="$t('alism')"
              hide-details=""
              prepend-inner-icon="search"
              outlined=""
              filled=""
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              v-model="filter.GroupId"
              :items="$global.state.groups"
              item-text="name"
              item-value="id"
              :label="$t('almjmwaat')"
              hide-details="auto"
              outlined=""
              clearable
              filled=""
              dense
              @change="getItems"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-data-table
          class="mytable"
          :items-per-page="$global.state.filter.pageSize"
          :items="$global.state.users"
          :loading="$global.state.loading"
          :headers="headers"
          hide-default-footer
          disable-pagination
          :loading-text="$t('loading')"
          :no-data-text="$t('no-data')"
          :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
        >
          <template v-slot:item.phoneNumber="{ item }">
            <div dir="ltr">{{ item.phoneNumber }}</div>
          </template>

          <template v-slot:item.imageUrl="{ item }">
            <v-avatar rounded="">
              <v-img
                :src="
                  item.imageUrl
                    ? $config.BASE_URL_PROD + '/' + item.imageUrl
                    : $store.state.defaultPhoto
                "
              />
            </v-avatar>
          </template>

          <template v-slot:item.isActive="{ item }">
            <v-chip v-if="item.isActive == true" color="teal" dark>{{
              $t("faal")
            }}</v-chip>
            <v-chip v-if="item.isActive == false" color="error" dark>
              {{ $t("ghyr-mfal") }}
            </v-chip>
          </template>

          <template v-slot:item.userGroups="{ item }">
            <v-chip v-for="(group, i) in item.userGroups" :key="i" label>
              <h4>{{ group.groupName }}</h4>
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item, index }">
            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn @click="deleteItem(item.id, index)" v-on="on" icon>
                  <v-icon color="error"> delete </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('hthf') }}</span>
            </v-tooltip>

            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="activeItem(item.id, index, item.isActive)"
                  v-on="on"
                  icon
                >
                  <v-icon color="error" v-if="item.isActive == true">
                    remove_circle
                  </v-icon>
                  <v-icon color="teal" v-if="item.isActive == false">
                    check_circle
                  </v-icon>
                </v-btn>
              </template>
              <span>{{
                item.isActive ? $t("ilghaa-altfayl") : $t("tfayl")
              }}</span>
            </v-tooltip>

            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn @click="openEditDialog(item)" v-on="on" icon>
                  <v-icon color="info"> edit </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip>

            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn @click="openChangePasswordDialog(item)" v-on="on" icon>
                  <v-icon color=""> lock_reset </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tghyyr-klmh-almrwr") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Pagination /> 
    <Dialog />
    <ChangePasswordDialog />
  </div>
</template>

<script>
export default {
  components: {
    Dialog: () => import("./dialog"),
    ChangePasswordDialog: () => import("./changePasswordDialog"),
  },
  data() {
    return {
      filter: {
        FullName: "",
        RoleId: "",
        ProvinceId: "",
        GroupId: "",
        search: "",
      },
      headers: [
        {
          text: "",
          value: "imageUrl",
        },
        {
          text: this.$t("alism-althlathy"),
          value: "fullName",
        },
        {
          text: this.$t("username"),
          value: "userName",
        },
        // {
        //   text: this.$t("rqm-alhatf"),
        //   value: "phoneNumber",
        //   align: "right",
        // },
        // {
        //   text: this.$t("albryd-alilktrwny"),
        //   value: "email",
        // },
        {
          text: this.$t("almnzmh"),
          value: "organization.name",
        },
        {
          text: this.$t("alhalh"),
          value: "isActive",
        },
        {
          text: this.$t("almjmwaat"),
          value: "userGroups",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  mounted() {
    this.getItems();
    this.$global.dispatch(`getGroup`);
    this.$eventBus.$on(`refresh`, () => {
      this.getItems();
    });
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
    this.$eventBus.$off(`delete`);
    this.$eventBus.$off(`active`);
  },

  methods: {
    getItems() {
      this.filter.search = this.filter.FullName;
      this.$global.dispatch(`get${this.$route.meta.endPoint}`, this.filter);
      console.log(this.$global.state.users);
    },

    async deleteItem(id, i) {
      await this.$service.deleteItem(
        id,
        `${this.$route.meta.endPoint}`,
        `delete`,
        this.$t("hl-ant-mtakd-mn-htha-alijraa")
      );
      await this.$eventBus.$once(`delete`, () => {
        setTimeout(() => {
          this.getItems();
        }, 10);
      });
    },

    async activeItem(id, i, isActive) {
      await this.$service.activeItem(
        id,
        `User/Active`,
        `active`,
        isActive == true
          ? this.$t("hl-ant-mtakd-mn-ilghaa-tghayl-htha-almstkhdm")
          : this.$t("hl-ant-mtakd-mn-tfayl-htha-almstkhdm"),
        isActive == true ? "تم إلغاء التفعيل بالنجاح" : "تم التفعيل بالنجاح"
      );
      await this.$eventBus.$once(`active`, () => {
        this.$global.state.users[i].isActive = !isActive;
        setTimeout(() => {
          this.getItems();
        }, 30);
      });
    },

    openAddDialog() {
      var item = {
        dialogType: "add",
      };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },

    openChangePasswordDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setChangePasswordDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },
};
</script>
